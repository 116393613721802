import axios from "axios";
import { getToken } from "./tokenService";

const BACKEND_URL = process.env.REACT_APP_BACKEND_SERVICE_URL;

const getHeaders = () => {
    const token = getToken();
    if (!token) {
        throw new Error("User not authenticated");
    }
    return {
        Authorization: token,
    };
};

export const updateBotConfig = async (botConfig) => {
    const headers = getHeaders();
    try {
        const payload = { botConfig };
        const response = await axios.post(
            `${BACKEND_URL}/api/bot-config/update-bot-config`,
            payload,
            { headers }
        );
        localStorage.setItem("user", JSON.stringify(response.data.user));
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const updateAmazonConfig = async (amazonConfiguration) => {
    const headers = getHeaders();
    try {
        const payload = { amazonConfig: amazonConfiguration };
        const response = await axios.post(
            `${BACKEND_URL}/api/bot-config/update-amazon-config`,
            payload,
            { headers }
        );
        localStorage.setItem("user", JSON.stringify(response.data.user));
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const updateFlipkartConfig = async (flipkartConfig) => {
    const headers = getHeaders();
    try {
        const payload = { flipkartConfig };
        const response = await axios.post(
            `${BACKEND_URL}/api/bot-config/update-flipkart-config`,
            payload,
            { headers }
        );
        localStorage.setItem("user", JSON.stringify(response.data.user));
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const updateAdmitadConfig = async (admitadConfig) => {
    const headers = getHeaders();
    try {
        const payload = { admitadConfig };
        const response = await axios.post(
            `${BACKEND_URL}/api/bot-config/update-admitad-config`,
            payload,
            { headers }
        );
        localStorage.setItem("user", JSON.stringify(response.data.user));
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const updateBitlyTokens = async (bitlyTokens) => {
    const headers = getHeaders();
    try {
        const payload = { bitlyTokens };
        const response = await axios.post(
            `${BACKEND_URL}/api/bot-config/update-bitly-tokens`,
            payload,
            { headers }
        );
        localStorage.setItem("user", JSON.stringify(response.data.user));
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const updateMessageConfig = async (messageConfig) => {
    const headers = getHeaders();
    try {
        const payload = { messageConfig };
        const response = await axios.post(
            `${BACKEND_URL}/api/bot-config/update-message-config`,
            payload,
            { headers }
        );
        localStorage.setItem("user", JSON.stringify(response.data.user));
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const updateTextReplacement = async (textReplacements) => {
    const headers = getHeaders();
    try {
        const payload = { textReplacements };
        const response = await axios.post(
            `${BACKEND_URL}/api/bot-config/update-text-replacements`,
            payload,
            { headers }
        );
        localStorage.setItem("user", JSON.stringify(response.data.user));
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const updateLogoConfiguration = async (enableLogoByDefault, logoFile) => {
    const headers = getHeaders();
    try {
        const formData = new FormData();
        formData.append("enableLogoByDefault", enableLogoByDefault);
        formData.append("logo", logoFile);

        const response = await axios.post(
            `${BACKEND_URL}/api/bot-config/upload-logo`,
            formData,
            {
                headers: {
                    ...headers,
                    "Content-Type": "multipart/form-data",
                },
            }
        );

        console.log("Logo updated successfully", response.data);
        localStorage.setItem("user", JSON.stringify(response.data.user));
        return response.data;
    } catch (error) {
        console.error("Error updating logo", error);
        throw error;
    }
};

export const updateForwardChannels = async (forwardChannels) => {
    const headers = getHeaders();
    try {
        const payload = { forwardChannels };
        const response = await axios.post(
            `${BACKEND_URL}/api/bot-config/update-forward-channels`,
            payload,
            { headers }
        );
        localStorage.setItem("user", JSON.stringify(response.data.user));
        return response.data;
    } catch (error) {
        throw error;
    }
};
