// router.js or your routes configuration file
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import FullLayout from '../layouts/FullLayout/FullLayout';
import BotConfig from '../views/pages/BotConfig';
import FlipkartConfig from '../views/pages/FlipkartConfig';
import AdmitadConfig from '../views/pages/AdmitadConfig';
import BitlyConfig from '../views/pages/BitlyConfig';
//import TextReplacementConfig from '../views/pages/TextReplacementConfig';
import LogoConfig from '../views/pages/LogoConfig';
import MessageConverter from '../views/pages/MessageConverter';
import MessageConfig from '../views/pages/MessageConfig';
import AmazonConfig from '../views/pages/AmazonConfig';
import ForwardChannelConfig from '../views/pages/ForwardChannelConfig';

const ThemeRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<FullLayout />}>
        {/* Nested route for AmazonConfig under FullLayout */}
        <Route path="/" element={<MessageConverter />} />
        <Route path="config/botConfig" element={<BotConfig />} />
        <Route path="config/messageConfig" element={<MessageConfig />} />
        <Route path="config/amazonConfig" element={<AmazonConfig />} />
        <Route path="config/flipkartConfig" element={<FlipkartConfig />} />
        <Route path="config/admitadConfig" element={<AdmitadConfig />} />
        <Route path="config/bitlyConfig" element={<BitlyConfig />} />
        <Route path="config/logoConfig" element={<LogoConfig />} />
        <Route path="feature/convertMessage" element={<MessageConverter />} />
        <Route path="config/forwardChannels" element={<ForwardChannelConfig />} />
      </Route>
    </Routes>
  );
};

export default ThemeRoutes;