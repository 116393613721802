import React from "react";
import { Box, Icon, Typography, Link } from "@mui/material";
import { FaHeart } from "react-icons/fa";

const Footer = () => {
  return (
    <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Link
          href="https://www.linkedin.com/in/arorashubham95/"
          target="_blank"
          rel="noopener noreferrer"
          sx={{
            textDecoration: "none",
            color: "inherit",
            display: "flex",
            alignItems: "center",
            "&:hover": {
              textDecoration: "underline",
            },
          }}
        >
          <Typography variant="body2" component="span">
            Made with
          </Typography>
          <Icon sx={{ color: "red", mx: 0.5, display: "flex", alignItems: "center" }}>
            <FaHeart size={15} />
          </Icon>
        </Link>
      </Box>
    </Box>
  );
};

export default Footer;