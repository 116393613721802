import React, { useEffect, useState } from "react";
import {
    CardContent,
    Typography,
    TextField,
    Button,
    List,
    ListItem,
    IconButton,
    Snackbar,
    Alert,
    Backdrop,
    CircularProgress,
    Grid
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { updateForwardChannels } from "../../services/botConfigService";
import BaseCard from "../../components/BaseCard/BaseCard";

const ForwardChannelConfigForm = () => {
    const [forwardChannels, setForwardChannels] = useState([]);
    const [newChannel, setNewChannel] = useState("");
    const [loading, setLoading] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarConfig, setSnackbarConfig] = useState({});

    let currentUser = JSON.parse(localStorage.getItem("user"));

    const handleAddChannel = () => {
        if (newChannel.trim() !== "") {
            setForwardChannels([...forwardChannels, newChannel.trim()]);
            setNewChannel("");
        }
    };

    const handleRemoveChannel = (index) => {
        const newForwardChannels = [...forwardChannels];
        newForwardChannels.splice(index, 1);
        setForwardChannels(newForwardChannels);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            const response = await updateForwardChannels(forwardChannels);
            snackbarConfig.message = response.message;
            snackbarConfig.severity = "success";
        } catch (error) {
            snackbarConfig.message = error.message;
            snackbarConfig.severity = "error";
            console.error("Error:", error);
        }
        setSnackbarConfig(snackbarConfig);
        setSnackbarOpen(true);
        setLoading(false);
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setSnackbarOpen(false);
    };

    useEffect(() => {
        initializeComponent();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const initializeComponent = () => {
        if (currentUser && currentUser.forwardChannels) {
            setForwardChannels(currentUser.forwardChannels || []);
        }
    };

    return (
        <div>
            <BaseCard
                title="Forward Channel Configuration"
                helpText="Manage the channels to which messages will be forwarded."
            >
                <CardContent sx={{ padding: "30px" }}>
                    <form onSubmit={handleSubmit}>
                        <Typography sx={{ fontSize: "14px", fontWeight: "500", mb: 2 }}>
                            Total Forward Channels: {forwardChannels.length}
                        </Typography>
                        <List>
                            {forwardChannels.map((channel, index) => (
                                <ListItem
                                    key={index}
                                    secondaryAction={
                                        <IconButton
                                            edge="end"
                                            aria-label="delete"
                                            onClick={() => handleRemoveChannel(index)}
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    }
                                >
                                    <TextField variant="outlined" value={channel} disabled fullWidth>{channel}</TextField>
                                </ListItem>
                            ))}
                        </List>
                        <Grid container spacing={2}>
                            <Grid item xs={9}>
                                <TextField
                                    label="New Channel ID"
                                    variant="outlined"
                                    value={newChannel}
                                    onChange={(e) => setNewChannel(e.target.value)}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <Button
                                    onClick={handleAddChannel}
                                    variant="outlined"
                                    fullWidth
                                    sx={{ height: '100%' }}
                                >
                                    Add Channel
                                </Button>
                            </Grid>
                        </Grid>
                        <Button
                            type="submit"
                            size="large"
                            color="primary"
                            variant="contained"
                            sx={{ mt: 2 }}
                        >
                            Save
                        </Button>
                    </form>
                </CardContent>
            </BaseCard>
            <Backdrop
                sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Snackbar
                open={snackbarOpen}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
            >
                <Alert onClose={handleSnackbarClose} severity={snackbarConfig.severity}>
                    {snackbarConfig.message}
                </Alert>
            </Snackbar>
        </div>
    );
};

export default ForwardChannelConfigForm;