import React from "react";

import { Grid } from "@mui/material";
import { Helmet } from "react-helmet";
import ForwardChannelConfigForm from "../forms/ForwardChannelConfigForm";

const ForwardChannelConfig = () => {
  return (
    <Grid container spacing={0}>
      <Grid item lg={12} md={12} xs={12}>
        <Helmet>
          <title>EZAffliate - Forward Channel Configuration</title>
          <meta
            name="description"
            content="With EZAffiliate you can easily create and manage your affiliate links. Use this page to configure the forward channels where the telegram message will be sent."
          />
        </Helmet>
        <ForwardChannelConfigForm />
      </Grid>
    </Grid>
  );
};

export default ForwardChannelConfig;
